* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  direction: rtl;
  font-family: "Noto Kufi Arabic", sans-serif;
  background: url(https://static.independent.co.uk/s3fs-public/thumbnails/image/2018/04/09/20/istock-157528129.jpg?width=1200)
    no-repeat;
  background-size: cover;
  object-fit: cover;
  height: 100vh;
  background-origin: padding-box;
}

nav {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #c89253;
  border-radius: 0 0 15px 15px;
  padding: 20px;
  font-weight: bold;
}

nav .list ul {
  list-style: none;
  width: 200px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  color: black;
}
nav .list ul a {
  text-decoration: none;
  color: black;
}

main {
  width: 100%;
  height: 600px;
}

main h2 {
  text-align: center;
  color: white;
}
.products {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  height: 650px;
}
.products .coffee {
  width: 250px;
  height: 300px;
  background-color: #fff;
  box-shadow: 0 0 10px;
  border-radius: 15px;
  text-align: center;
}
.products .coffee .small {
  width: 100%;
  border-radius: 15px 15px 0 0;
  height: 230px;
}

button {
  width: 100px;
  height: 25px;
  border: solid 1px;
  font-weight: bold;
  background-color: #777;
  border-radius: 5px;
  transition: 0.2s;
  cursor: pointer;
}
button:hover {
  transform: scale(1.1);
  background-color: #222;
  color: white;
}

.cart {
  background-color: #9998;
  width: 250px;
  height: auto;
  margin: 40px auto;
  border-radius: 15px;
  font-weight: bold;
  color: #222;
}
.cart h2 {
  text-align: center;
}
.text-right {
  text-align: right;
}
.block {
  display: flex;
  justify-content: space-between;
}

.col-1 {
  flex: 1;
}
.col-2 {
  flex: 2;
}

.add,
.rem {
  width: 25px;
  margin: 0 5px;
}

.cart .lastButton{
      margin: 0 89px;
}

@media screen and (max-width: 450px) {
  body {
    background: url(https://wallpaper.dog/large/20496064.jpg) no-repeat;
  }
  main {
    height: 700px;
  }
  .products {
    flex-direction: row;
    align-items: center;
  }
  .products .coffee {
    margin: 15px 0;
  }
  .cart .lastButton {
    margin: 0 89px;
  }
  nav{
    width: 100%;
    justify-content: space-around;
  }
  nav .list ul{
    width: 164px;
  }
}
